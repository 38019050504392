<template>
  <base-layout page-title="Create Wi-Fi Access Point">
    <template v-slot:main-content>
      <div id="container" class="ion-padding">
        <!-- <h2>Success</h2> -->
        <div class="ion-text-center">
          <img src="../assets/img/success.png">
        </div>
        <h1>Your VEDA is online and ready to go</h1>
        <p>Your VEDA meter is currently linked to a hotspot. Connect it with a stable Wi-Fi connection to have easy accessibility</p>
        <ion-button router-link="/update-wifi-settings" expand="block" fill="outline" color="primary">CONNECT WITH MY Wi-Fi</ion-button>
      </div>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from './BaseLayout.vue'
import { IonButton } from "@ionic/vue"

export default {
  name: 'CreateWifiAPSuccess',
  components: {
    IonButton,
    BaseLayout
  },
  methods: {
  }
}
</script>