
import BaseLayout from './BaseLayout.vue'
import { IonButton } from "@ionic/vue"

export default {
  name: 'CreateWifiAPSuccess',
  components: {
    IonButton,
    BaseLayout
  },
  methods: {
  }
}
